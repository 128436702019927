<template>
  <div>
    <b-overlay :show="loadingShow" rounded="lg" opacity="1">
      <div>
        <h1 class="mb-3">Meus Dados</h1>
        <b-alert variant="success" show>
          <div v-if="confirmaAtualizacao" class="alert-body">
            <span
              ><strong class="color-msg-success"
                >Dados atualizados com sucesso!</strong
              ></span
            >
          </div>
        </b-alert>
        <b-alert variant="danger" show>
          <div v-if="erros != null" class="alert-body">
            <strong>Registro não efetuado </strong>
            <ul id="array-with-index">
              <li v-for="erro in erros" :key="erro.errors">
                {{ erro }}
              </li>
            </ul>
          </div>
        </b-alert>
        <validation-observer ref="simpleRules">
          <b-overlay :show="overlayAtualizar" rounded="lg" :opacity="0.8">
            <b-form id="PessoaForm" ref="PessoaForm" @submit.prevent="submit()">
              <div id="DadosPessoas" class="formulario">
                <h3>Dados Pessoais</h3>
                <b-row>
                  <b-col md="6" xl="6" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="Nome"
                      rules="required"
                    >
                      <b-form-group
                        label="Nome Completo *"
                        label-for="Pessoa-Nome"
                      >
                        <b-form-input
                          id="nome"
                          v-model="formValues.nome"
                          :state="errors.length > 0 ? false : null"
                          name="Pessoa-Nome"
                          :disabled="formDisabled.nome"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="CPF"
                      rules="required"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : true"
                        label="CPF *"
                        label-for="Pessoa-CPF"
                      >
                        <Cleave
                          id="Pessoa-CPF"
                          v-model="formValues.cpf"
                          class="form-control"
                          :options="mask.cpf"
                          :disabled="formDisabled.cpf"
                          @blur="validaCPF"
                        />

                        <small v-if="cpfExiste" class="text-danger"
                          >CPF já consta em nosso sistema<br
                        /></small>
                        <small v-if="errors.length > 0" class="text-danger"
                          >{{ errors[0] }} <br
                        /></small>
                        <small
                          v-if="!cpfValido && formValues.cpf != ''"
                          class="text-danger"
                          >CPF inválido</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="nascimento"
                      rules="required"
                    >
                      <b-form-group
                        label="Data de nascimento *"
                        label-for="Pessoa-nascimento"
                      >
                        <b-form-input
                          id="dtNascimento"
                          v-model="formValues.dtNascimento"
                          :state="errors.length > 0 ? false : null"
                          name="Pessoa-nascimento"
                          :disabled="formDisabled.dtNascimento"
                          placeholder="01/01/2000"
                          type="date"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" xl="6" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      :rules="'required|email'"
                    >
                      <b-form-group label="E-mail *" label-for="Pessoa-email">
                        <b-form-input
                          id="email"
                          v-model="formValues.email"
                          :state="errors.length > 0 ? false : null"
                          name="Pessoa-email"
                          :disabled="formDisabled.email"
                          type="email"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="telefone"
                      rules="length:11"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                        label="Telefone"
                        label-for="Pessoa-telefone"
                      >
                        <Cleave
                          id="telefone"
                          v-model="formValues.telefone"
                          class="form-control"
                          :options="mask.fone"
                          name="Pessoa-telefone"
                          :disabled="formDisabled.telefone"
                          placeholder="(00) 0 0000 0000"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          "Telefone Inválido"
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" xl="3" class="mb-1">
                    <generoSelect
                      :values="formValues.genero"
                      :id-genero="idGenero"
                      :required="false"
                      :disabled-prop="formDisabled.genero"
                      @getGenero="getgenero"
                    />
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <estadoCivilSelect
                      :values="formValues.estadoCivil"
                      :id-estado-civil="idEstadoCivil"
                      :required="false"
                      :disabled-prop="formDisabled.estadoCivil"
                      @getEstadoCivil="getestadoCivil"
                    />
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="Número de filhos"
                      rules="between:0,30"
                    >
                      <b-form-group
                        label="Número de filhos"
                        label-for="Pessoa-filhos"
                      >
                        <b-form-input
                          id="filhos"
                          v-model="formValues.filhos"
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          name="Pessoa-filhos"
                          :disabled="formDisabled.filhos"
                          @keypress="onlyNumber"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <escolaridadeSelect
                      :values="formValues.escolaridade"
                      :id-escolaridade="idEscolaridade"
                      :required="false"
                      :disabled-prop="formDisabled.escolaridade"
                      @getEscolaridade="getescolaridade"
                    />
                  </b-col>
                </b-row>
              </div>
              <div id="DadosEmpresariais" class="formulario">
                <h3>Dados Empresariais</h3>
                <b-row>
                  <b-col md="6" xl="6" class="mb-1">
                    <label for=""> Empresa </label>

                    <fake-input :content="formValues.empresa.razao" />
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <unidadeSelect
                      :values="formValues.unidade"
                      :id-unidade="idUnidade"
                      :required="false"
                      :disabled-prop="formDisabled.unidade"
                      @getUnidade="getunidade"
                    />
                  </b-col>

                  <b-col md="3" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="admissao"
                      rules=""
                    >
                      <b-form-group
                        label="Data de Admissão"
                        label-for="Pessoa-admissao"
                      >
                        <b-form-input
                          id="admissao"
                          v-model="formValues.dtAdmissao"
                          :state="errors.length > 0 ? false : null"
                          name="Pessoa-admissao"
                          :disabled="formDisabled.dtAdmissao"
                          placeholder="01/01/2000"
                          type="date"
                        />
                        <small v-if="errors.length > 0" class="text-danger">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3" xl="3" class="mb-1">
                    <funcaoSelect
                      :values="formValues.funcao"
                      :id-funcao="idFuncao"
                      :required="false"
                      :disabled-prop="formDisabled.funcao"
                      @getFuncao="getfuncao"
                    />
                  </b-col>
                  <b-col md="3" xl="3" class="mb-1">
                    <setorSelect
                      :values="formValues.setor"
                      :id-setor="idSetor"
                      :required="false"
                      :disabled-prop="formDisabled.setor"
                      @getSetor="getsetor"
                    />
                  </b-col>
                  <b-col md="6" xl="3" class="mb-1">
                    <validation-provider
                      #default="{ errors }"
                      name="Renda"
                      rules="renda-bruta-colaborador"
                    >
                      <b-form-group
                        :state="errors.length > 0 ? false : null"
                        label="Renda Bruta"
                        label-for="Pessoa-renda"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            R$
                          </b-input-group-prepend>
                          <Cleave
                            id="renda"
                            v-model="formValues.renda"
                            class="form-control"
                            :options="mask.renda"
                            name="Renda"
                            placeholder="0,00"
                            :disabled="formDisabled.renda"
                          />
                        </b-input-group>
                        <small
                          v-if="errors.length > 0 || formValues.renda < 0"
                          class="text-danger"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" xl="3" class="mb-1">
                    <regimeSelect
                      :values="formValues.regime"
                      :id-regime="idRegime"
                      :required="false"
                      :disabled-prop="formDisabled.regime"
                      @getRegime="getregime"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6" xl="3" class="mb-1">
                    <turnoSelect
                      :values="formValues.turno"
                      :id-turno="idTurno"
                      :required="false"
                      :disabled-prop="formDisabled.turno"
                      @getTurno="getturno"
                    />
                  </b-col>
                  <b-col md="6" xl="3" class="mb-1">
                    <liderSelect
                      :values="formValues.lider"
                      :id-lider="idLider"
                      :id-empresa="idEmpresa"
                      :required="false"
                      :disabled-prop="formDisabled.lider"
                      @getLider="getlider"
                    />
                  </b-col>
                  <b-col md="6" xl="3" class="mb-1">
                    <gerenciaSelect
                      :values="formValues.gerencia"
                      :id-gerencia="idGerencia"
                      :required="false"
                      :disabled-prop="formDisabled.gerencia"
                      @getGerencia="getgerencia"
                    />
                  </b-col>
                  <b-col md="6" xl="3" class="mb-1">
                    <diretoriaSelect
                      :values="formValues.diretoria"
                      :id-diretoria="idDiretoria"
                      :required="false"
                      :disabled-prop="formDisabled.diretoria"
                      @getDiretoria="getdiretoria"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12" align="center">
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      class="mr-1"
                      @click.prevent="cancel"
                    >
                      Desfazer
                    </b-button>

                    <b-button
                      type="submit"
                      variant="custom"
                      @click.prevent="submit"
                    >
                      Salvar
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </b-overlay>
        </validation-observer>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BSpinner,
  BOverlay,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BForm,
  BAlert,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, between, min, max, length, email } from "@validations";
import escolaridadeSelect from "@/views/components/custom/pessoas/EscolaridadeSelect.vue";
import generoSelect from "@/views/components/custom/pessoas/GeneroSelect.vue";
import estadoCivilSelect from "@/views/components/custom/pessoas/EstadoCivilSelect.vue";
import regimeSelect from "@/views/components/custom/pessoas/RegimeSelect.vue";
import turnoSelect from "@/views/components/custom/pessoas/TurnoSelect.vue";
import funcaoSelect from "@/views/components/custom/pessoas/FuncaoSelect.vue";
import gerenciaSelect from "@/views/components/custom/pessoas/GerenciaSelect.vue";
import diretoriaSelect from "@/views/components/custom/pessoas/DiretoriaSelect.vue";
import setorSelect from "@/views/components/custom/pessoas/SetorSelect.vue";
import unidadeSelect from "@/views/components/custom/pessoas/UnidadeSelect.vue";
import liderSelect from "@/views/components/custom/pessoas/LiderSelect.vue";
import FakeInput from "@/views/components/custom/inputs/FakeInput.vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BForm,
    BAlert,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BButton,
    BSpinner,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    estadoCivilSelect,
    escolaridadeSelect,
    regimeSelect,
    turnoSelect,
    funcaoSelect,
    gerenciaSelect,
    diretoriaSelect,
    setorSelect,
    unidadeSelect,
    liderSelect,
    generoSelect,
    FakeInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      between,
      min,
      max,
      length,
      email,
      render: true,
      DadosUsuario: {},
      loadingShow: true,
      overlayAtualizar: false,
      erros: null,
      confirmaAtualizacao: false,
      formValues: {
        nome: "",
        cpf: "",
        dtNascimento: "",
        perfil: [],
        email: "",
        telefone: "",
        genero: {},
        estadoCivil: {},
        filhos: 0,
        escolaridade: {},
        empresa: {
          id_empresa: "",
          razao: "",
          cnpj: "",
          telefone: "",
          email: "",
          site: "",
          id_cidade: "",
          id_tipo_empresa: "",
          cep: "",
          ativo: "",
          nome_fantasia: "",
          cidade: {
            id_cidade: "",
            nome: "",
            uf: "",
          },
          tipo_empresa: {
            id_tipo_empresa: "",
            descricao: "",
          },
          regionais: [],
        },
        unidade: {},
        dtAdmissao: "",
        regime: {},
        renda: 0.0,
        turno: {},
        funcao: {},
        setor: {},
        gerencia: {},
        diretoria: {},
        lider: {},
      },
      formDisabled: {
        cpf: true,
        renda: false,
        dtAdmissao: false,
        filhos: false,
        genero: false,
        dtNascimento: false,
        telefone: false,
        email: false,
        nome: false,
        gerencia: false,
        diretoria: false,
        lider: false,
        empresa: false,
        funcao: false,
        setor: false,
        turno: false,
        regime: false,
        escolaridade: false,
        estadoCivil: false,
        unidade: false,
      },
      cpfValido: true,
      cpfExiste: false,
      cpfError: false,
      mask: {
        cpf: {
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
          numericOnly: true,
        },
        fone: {
          delimiters: ["(", ")", " ", " ", " "],
          blocks: [0, 2, 1, 4, 4],
          numericOnly: true,
        },
        dtNascimento: {
          delimiters: ["/", "/"],
          blocks: [2, 2, 4],
          numericOnly: true,
        },
        renda: {
          numeral: true,
          numeralDecimalScale: 2,
          signBeforePrefix: true,
          numeralDecimalMark: ",",
          delimiter: "",
        },
      },

      idEstadoCivil: 0,
      idEscolaridade: 0,
      idRegime: 0,
      idTurno: 0,
      idFuncao: 0,
      idSetor: 0,
      idGerencia: 0,
      idDiretoria: 0,
      idUnidade: 0,
      idEmpresa: 0,
      idLider: 0,
      idGenero: 0,
    };
  },
  created() {
    this.popularDadosMinhaConta();
  },

  methods: {
    async popularDadosMinhaConta() {
      this.DadosUsuario = getUserData();
      this.$route.params.idGrupo =
        this.DadosUsuario.empresa.empresa_grupo.id_empresa_grupo;
      await this.buscaDadosEditar();
      await this.buscarDadosEmpresa();
    },

    buscaPerfisColab(id_colab) {
      try {
        this.loadingShow = true;
        const rotaPerfis = this.$helpers.preparaRotaComParametros(
          this.$api.BuscaPerfisColab,
          "{colaborador}",
          id_colab
        );
        this.$http.get(rotaPerfis).then((res) => {
          if (res.status === 200) {
            this.formValues.perfil = res.data;
            this.loadingShow = false;
          }
        });
      } catch (error) {
        this.loadingShow = false;
      }
    },
    async submit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.AtualizarDados();
        }
      });
    },
    async AtualizarDados() {
      const form = this.FormataDadosFormulario();

      this.overlayAtualizar = true;

      await this.$http
        .put(this.$api.MeusDados, form)
        .then((res) => {
          if (res.status === 200) {
            this.formValues.renda = res.data.renda_bruta;
            this.$store.commit(
              "sessions/DEFINE_NAME_USER",
              this.formValues.nome
            );
            this.confirmaAtualizacao = true;
            this.erros = null;
          }
        })
        .catch((error) => {
          this.confirmaAtualizacao = false;
          this.erros = error.response.data.error
            ? error.response.data.error
            : error.response.data.errors;
        });

      this.overlayAtualizar = false;
    },
    setaDadosFormulario(dados) {
      this.formValues.nome = dados.nome;
      this.formValues.cpf = dados.cpf;
      this.formValues.telefone = dados.telefone;
      this.formValues.dtNascimento = dados.nascimento;
      this.formValues.email = dados.email;
      this.formValues.filhos = dados.numero_filhos;
      this.formValues.renda = dados.renda_bruta;
      this.formValues.dtAdmissao = dados.admissao;
      this.idLider = dados.id_lider;
      this.idGenero = dados.id_genero;
      this.idEstadoCivil = dados.id_estado_civil;
      this.idEscolaridade = dados.id_escolaridade;
      this.idRegime = dados.id_regime;
      this.idTurno = dados.id_empresa_turno;
      this.idFuncao = dados.id_empresa_funcao;
      this.idSetor = dados.id_empresa_setor;
      this.idGerencia = dados.id_empresa_gerencia;
      this.idDiretoria = dados.id_empresa_diretoria;
      this.idUnidade = dados.id_empresa_unidade;
      this.idEmpresa = dados.id_empresa;
    },

    limpaForm() {
      this.formValues.cpf = "";
      this.formValues.nome = "";
      this.formValues.perfil = [];
      this.formValues.email = "";
      this.formValues.telefone = "";
      this.formValues.dtNascimento = "";

      this.formValues.genero = {};

      this.formValues.estadoCivil = {};
      this.idEstadoCivil = 0;

      this.formValues.filhos = 0;
      this.formValues.escolaridade = {};

      this.formValues.dtAdmissao = "";
      this.formValues.regime = {};
      this.formValues.renda = "";
      this.formValues.turno = {};

      this.formValues.funcao = {};
      this.formValues.setor = {};
      this.formValues.gerencia = {};
      this.formValues.diretoria = {};
      this.formValues.lider = {};
      this.formValues.empresa = {
        id_empresa: "",
        razao: "",
        cnpj: "",
        telefone: "",
        email: "",
        site: "",
        id_cidade: "",
        id_tipo_empresa: "",
        cep: "",
        ativo: "",
        nome_fantasia: "",
        cidade: {
          id_cidade: "",
          nome: "",
          uf: "",
        },
        tipo_empresa: {
          id_tipo_empresa: "",
          descricao: "",
        },
        regionais: [],
      };
      this.formValues.unidade = {};
    },

    FormataDadosFormulario() {
      const idColaborador = this.DadosUsuario.colaborador.id_colaborador;
      const perfis = this.$helpers.retornaAtributoArray(
        this.formValues.perfil,
        "id_perfil"
      );

      return {
        id_colaborador: idColaborador,
        cpf: this.formValues.cpf,
        email: this.formValues.email,
        nome: this.formValues.nome,
        id_perfil: perfis,
        telefone: this.formValues.telefone,
        nascimento: this.formValues.dtNascimento,
        id_genero: this.formValues.genero
          ? this.formValues.genero.id_genero
          : "",
        id_estado_civil: this.formValues.estadoCivil
          ? this.formValues.estadoCivil.id_estado_civil
          : "",
        numero_filhos: this.formValues.filhos,
        id_escolaridade: this.formValues.escolaridade
          ? this.formValues.escolaridade.id_escolaridade
          : "",
        admissao: this.formValues.dtAdmissao,
        id_regime: this.formValues.regime
          ? this.formValues.regime.id_regime
          : "",
        renda_bruta: this.formValues.renda,
        id_empresa_turno: this.formValues.turno
          ? this.formValues.turno.id_empresa_turno
          : "",
        id_empresa_funcao: this.formValues.funcao
          ? this.formValues.funcao.id_empresa_funcao
          : "",
        id_empresa_setor: this.formValues.setor
          ? this.formValues.setor.id_empresa_setor
          : "",
        id_empresa_gerencia: this.formValues.gerencia
          ? this.formValues.gerencia.id_empresa_gerencia
          : "",
        id_empresa_diretoria: this.formValues.diretoria
          ? this.formValues.diretoria.id_empresa_diretoria
          : "",
        id_lider: this.formValues.lider
          ? this.formValues.lider.id_colaborador
          : "",
        id_empresa: this.formValues.empresa
          ? this.formValues.empresa.id_empresa
          : "",
        id_empresa_unidade: this.formValues.unidade
          ? this.formValues.unidade.id_empresa_unidade
          : "",
        id_empresa_grupo: this.$route.params.idGrupo,
      };
    },
    async validaCPF(cpf) {
      this.cpfValido = this.$helpers.validarCPF(cpf);
      if (this.cpfValido) this.verificaCpfExiste(cpf);
    },

    async buscaDadosEditar() {
      this.loadingShow = true;

      await this.$http.get(this.$api.DadosMinhaConta).then((res) => {
        if (res.status === 200) {
          this.setaDadosFormulario(res.data);
          this.idLider = res.data.id_lider;
          this.formDisabled.empresa = true;
          this.buscaPerfisColab(this.DadosUsuario.colaborador.id_colaborador);
        }
      });

      this.loadingShow = false;
    },

    async buscarDadosEmpresa() {

      await this.$http.get(this.$api.buscaEmpresaUsuario).then((res) => {
        if (res.status === 200) {
          this.formValues.empresa = res.data;
        }
      });

      this.loadingShow = false;
    },

    verificaCpfExiste(cpf) {
      try {
        this.loadingShow = true;
        const rotaCpf = this.$helpers.preparaRotaComParametros(
          this.$api.BuscaPessoaCpf,
          "{cpf}",
          cpf
        );
        this.$http.get(rotaCpf).then((res) => {
          this.cpfExiste = res.status === 200;
          this.loadingShow = false;
        });
      } catch (error) {
        this.loadingShow = false;
      }
    },
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    getestadoCivil(estadoCivilRecebido) {
      this.formValues.estadoCivil = estadoCivilRecebido;
    },
    getescolaridade(Estadoescolaridade) {
      this.formValues.escolaridade = Estadoescolaridade;
    },
    getunidade(Estadounidade) {
      this.formValues.unidade = Estadounidade;
    },
    getregime(Estadoregime) {
      this.formValues.regime = Estadoregime;
    },
    getturno(Estadoturno) {
      this.formValues.turno = Estadoturno;
    },
    getgerencia(Estadogerencia) {
      this.formValues.gerencia = Estadogerencia;
    },
    getfuncao(Estadofuncao) {
      this.formValues.funcao = Estadofuncao;
    },
    getdiretoria(Estadodiretoria) {
      this.formValues.diretoria = Estadodiretoria;
    },
    getsetor(Estadosetor) {
      this.formValues.setor = Estadosetor;
    },
    getlider(Estadolider) {
      this.formValues.lider = Estadolider;
    },
    getgenero(EstadoGenero) {
      this.formValues.genero = EstadoGenero;
    },
  },

};
</script>

<style scoped>
.formulario {
  margin-top: 20px;
}
</style>>
</style>
